import {Env} from 'src/environments/env';

export const environment: Env = {
    production: true,
    api_version: 2,
    base_path: 'https://preprod-eventmanager.cicg.ch/api/v2',
    base_path_auth: 'https://preprod-eventmanager.cicg.ch',
    storage_base_path: 'https://preprod-eventmanager.cicg.ch',
    client_id: '2',
    client_secret: 'kyWYOw0A90xUxnof2OjXrKTLl9xHu4c5NEGXQ8mC',
    cguPathFr: 'https://event.cicg.ch/legal/cgu_fr.pdf',
    cguPathEn: 'https://event.cicg.ch/legal/cgu_en.pdf',
    pcaPathFr: 'https://event.cicg.ch/legal/pca_fr.pdf',
    pcaPathEn: 'https://event.cicg.ch/legal/pca_en.pdf',
    appName: 'CICG',
    contactEmail: 'apps@cicg.ch',
    primary_color: '#c5033b',
    showAnimatedSplash: true,
    deeplinksURL: 'cicg.app.link',
    sentryEnv: 'staging'
};
